.inner-container{
    gap: 1.5rem;
    background-color: blue;
    padding: 2rem;
    border-radius: 20px;
    border: 6px solid rgb(96, 96, 184);
    text-align: center;
}
.inner-container .primaryText{
    color: aliceblue;
    font-weight: 600;
}
.inner-container .secondaryText{
    color: rgb(221, 209, 209);
}

.inner-container .button{
    background: blue;
    border: 2px solid white;
    box-shadow: var(--shadow);
    border-radius: 10px;
}
